// Copyright © 2024 The Things Industries B.V.

import React from 'react'
import { defineMessages } from 'react-intl'

import Icon, { IconUserPlus } from '@ttn-lw/components/icon'
import Link from '@ttn-lw/components/link'

import Message from '@ttn-lw/lib/components/message'

import ContentTemplate from './template'

const m = defineMessages({
  title: 'Support access has been requested',
  body: 'A login token for the "support" user is created. This one-time token is valid for one hour. Using this token, the support user has read-only permissions on all resources of your tenant. If a "support" user does not already exist for this tenant, one is created.',
  closing: 'You can review the support user <Link>here</Link>.',
  preview: 'Read-only access has been requested by the support team.',
})

const SupportAccessRequestedIcon = () => <Icon icon={IconUserPlus} className="c-tts-primary" />

const SupportAccessRequestedPreview = () => <Message content={m.preview} />

const SupportAccessRequestedTitle = () => <Message content={m.title} />

const SupportAccessRequested = () => {
  const messages = {
    body: m.body,
    action: m.closing,
  }
  const values = {
    action: {
      Link: msg => <Link to={'/admin-panel/user-management/support'}>{msg}</Link>,
    },
  }
  return <ContentTemplate messages={messages} values={values} />
}

SupportAccessRequested.Title = SupportAccessRequestedTitle
SupportAccessRequested.Preview = SupportAccessRequestedPreview
SupportAccessRequested.Icon = SupportAccessRequestedIcon

export default SupportAccessRequested
